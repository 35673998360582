import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Layout from './components/Main.vue'
import Layoutpage from './components/Layoutpage.vue'
import Common from './common/common'
import less from 'less'
import Config from './apis/config.js';
import Server from './common/server';
import store from './apis/store.js';

axios.defaults.baseURL = "https://admin.xichuanwh.com/"
//axios.defaults.baseURL = "http://read.com/"

Vue.prototype.$Config = Config

import VueCropper from 'vue-cropper'
Vue.use(VueCropper)
Vue.prototype.$Server = Server
Vue.prototype.$Common = Common
Vue.prototype.$http = axios
Vue.config.productionTip = false
Vue.use(ElementUI); 
Vue.use(less)
Vue.component('Layout', Layout)
Vue.component('Layoutpage', Layoutpage)
new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
