<template>
    <div class="page">
        <layout>
            <div class="stage_body">
                <div class="top_label">
                    <span class="lable_name">分层教学</span>
                </div>
                <div class="top_plan_info_title">
                    <img src="../../../static/image/wdbj.png"/>
                    <span>分层规则</span>
                </div>
                <div class="timeline">
                    <el-timeline>
          
                        <el-timeline-item v-for="(item,index) in stage" :key="index" :class="colorClass[index]" :timestamp="`${item.starttime_text} ~ ${item.endtime_text}`" placement="top">
                            <el-card>
                                <h4>{{ item.char }}</h4>
                                <p>{{ item.intro }}</p>
                            </el-card>
                        </el-timeline-item>
             
                        
                        <div class="timeline_info">
                            <span>提示1: 每日积分上限为10分，每做一篇材料获得2积分，每天超过十分不会再累计，请各位同学合理安排</span>
                            <span>提示2: 请不要使用自行注册的账号进行学习，阶段考试必须使用学号进行登录，使用自行注册的账号会导致无法参加考试</span>
                            <span v-if="store">提示3: 阶段学习每篇材料可以重置 <li style="color: #409EFF;display: inline-block;"> {{ store.frequency }} </li> 次,重置次数完不可再提交，重置 <li style="color: #409EFF;display: inline-block;">{{ store.frequency }}</li> 次后不论正确率是否达到都可以查看解析，每篇材料 <li style="color: #409EFF;display: inline-block;"> 正确率 >= {{ store.analysis }}% </li>可查看答案解析，重做后正确率未达到{{ store.analysis }}%依然不可查看解析</span>
                        </div>
                
                    </el-timeline>
                </div>
                
                <div class="top_plan_info_title">
                    <img src="../../../static/image/wdbj.png"/>
                    <span>阶段任务</span>
                </div>
                <div class="stage_task">
                    <div class="stage_task_left">
                        <el-row v-for="(item,index) in work_data" :key="index" :gutter="14">
                            <i style="float: left;" class="el-icon-warning"></i>
                            <el-col :span="16">
                                <el-card shadow="never">
                                    {{ index+1 }} 、{{ item.title }}
                                </el-card>
                                
                            </el-col>
                            <span class="dowork" @click="goDoWork(item.level,item.grade,item.trem,item.type)">前往做题 ></span>
                        </el-row>
                    </div>
                    <div class="stage_task_right">
                
                            <p style="display: inline-block;width: 100%;">
                                <span style="float: left;font-weight: 700;">今日积分</span>
                                <span class="stage_task_right_value">{{ today_grade.today_integrallist }} / {{ today_grade.schoolconfig_number }}</span>
                            </p>
                            <el-divider></el-divider>
                            <p style="display: inline-block;width: 100%;">
                                <span style="float: left;font-weight: 700;">总积分</span>
                                <span class="stage_task_right_value">{{ today_grade.now_level_integrallist }}</span>
                            </p>
                            <el-divider></el-divider>
                            <p style="display: inline-block;width: 100%;">
                                <span style="float: left;font-weight: 700;">完成数</span>
                                <span class="stage_task_right_value">{{ today_grade.submitanswers_count }}</span>
                            </p>
                            <el-divider></el-divider>
                            <p style="display: inline-block;width: 100%;">
                                <span style="float: left;font-weight: 700;">正确率</span>
                                <span class="stage_task_right_value">{{ today_grade.score }}</span>
                            </p>
                    
                    </div>
                </div>
                <div class="top_plan_info_title">
                    <img src="../../../static/image/wdbj.png"/>
                    <span>做题记录</span>
                </div>
                <el-table
                :data="tableData"
                style="width: 100%">
                <el-table-column
                    type="index"
                    width="50">
                    </el-table-column>
                    <el-table-column
                        prop="w_id_text"
                        label="标题"
                        >
                    </el-table-column>
                    <el-table-column
                        prop="score"
                        label="正确率"
                        width="120">
                    </el-table-column>
                    <el-table-column
                        prop="time"
                        label="时长"
                        width="120">
                    </el-table-column>
                    <el-table-column
                        prop="type"
                        label="类型"
                        width="100">
                    </el-table-column>
                    <el-table-column
                        prop="createtime"
                        label="做题时间"
                        width="120">
                    </el-table-column>

                    <!-- <el-table-column width="100" label="操作">
                        <template slot-scope="scope">
                            <el-button
                            size="mini"
                            type="primary"
                            @click="handleEdit(scope.$index, scope.row)">详情</el-button>
      
                        </template>
                    </el-table-column> -->
                </el-table>
                <el-pagination
                    style="margin-top: 20px;"
                    v-if="total_number"
                    @size-change="changeSize"
                    @current-change="changeNumber"
                    @prev-click="prev"
                    @next-click="next"
                    :page-size="pageSize"
                    :total="total_number">
                </el-pagination>
                <Stagework ref="stagework_"></Stagework>
            </div>
        </layout>
    </div>
</template>

<script>
import Http from "../../apis/student";
import stagework from "../../../untils/stagework";
export default {
    components:{
        'Stagework':stagework
    },
    data(){
        return{
            colorClass:['greentimelinetitle','bluetimelinetitle','yellowtimelinetitle','redtimelinetitle'],
            tableData: [],
            total_number:0,
            stage:[],
            today_grade:{},
            work_data:{},
            trem:0,
            level:'',
            page:1,
            pageSize:30,
            now_level:{},
            store:{}
        }
    },

    activated(){
        this.$store.dispatch('fetchConfig').then((res) => {
            this.store = res.school
        }).catch(error => {

            console.error('fetchConfig error:', error)
        })
        
        this.getStage_()

    },
    // beforeCreate(){
    //     this.$options.components.Stagework = require('./components/MenuData').default
    // },
    methods:{
        getStage_(){
            const loading = this.$loading({
                lock: true,
                text: '疯狂加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.5)'
            });
            Http.getStage().then((res) => {
                loading.close()
                
                this.stage = res.data.stage
                this.today_grade = res.data.today_grade
                this.work_data = res.data.work_data,
                this.trem = res.data.trem
                this.level = res.data.level
                this.now_level = res.data.now_level
                this.getWorkList();
            });

        },
        // 获取作业记录
        getWorkList(){
            var params = {}
            params.trem = this.trem
            params.level = this.level
            params.page = this.page
            params.pageSize = this.pageSize
            Http.getStageSubmit(params).then((res) => {
                this.tableData = res.data.list
                this.total_number = res.data.total_number
            });
        },

        goDoWork(level,grade,trem,type){
            var now = new Date().getTime();
            // 将毫秒数转换为秒数并取整
            var timestamp = Math.floor(now / 1000);
            //this.$refs.stagework_.getStageWork(level,grade,trem,type)
            if(this.now_level.starttime < timestamp && this.now_level.endtime > timestamp){
                this.$refs.stagework_.getStageWork(level,grade,trem,type)
            }else{
                this.$message({
                    message:'学习阶段还未开始',
                    type:'error'
                })
            }

            
        },
        // 上一页

        prev(e){

            this.page = e
            this.getWorkList()
        },

        // 下一页
        next(e){

            this.page = e
            this.getWorkList()
        },

        // 点击页码
        changeNumber(e){

            this.page = e
            this.getWorkList()
        },

        // 前往指定页数
        changeSize(e){

            this.page = e
            this.getWorkList()
        },
    }
}
</script>

<style scoped>

.stage_body >>> .el-timeline{
    text-align: left;
 
    margin-top: 40px;
}
.stage_body >>> .el-timeline-item{
    padding-bottom: 40px;
}
.timeline >>> .el-card{
    border: none;
}
.top_label{
    width: 100%;
    height: 30px;
    border-bottom: 1px solid #EBEEF5;
    margin-bottom: 20px;
    padding-bottom: 5px;
}
.lable_name{
    float: left;
    padding-left: 10px;
    border-left: 4px #409EFF solid;
}
.top_plan_info_title{
    width: 100%;
    height: 30px;
}
.top_plan_info_title img{
    width: 23px;
    height: 18px;
    float: left;
}
.top_plan_info_title span{
    float: left;
    margin-left: 10px;
    line-height: 18px;
    font-size: 14px;
    font-weight: 700;
}
.stage_body .greentimelinetitle >>> .el-timeline-item__timestamp{
    color: #1A9D30;
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 700;
}
.stage_body .greentimelinetitle >>> .el-card__body h4{
    color: #1A9D30;
}
.stage_body .greentimelinetitle >>> .el-card__body p{
    color: #000000;
    font-weight: 600;
}
.stage_body .greentimelinetitle >>> .el-card__body{
    background: #D4EAD8;
}

.stage_body .bluetimelinetitle >>> .el-timeline-item__timestamp{
    color: #3197D8;
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 700;
}
.stage_body .bluetimelinetitle >>> .el-card__body h4{
    color: #3197D8;
}
.stage_body .bluetimelinetitle >>> .el-card__body p{
    color: #000000;
    font-weight: 600;
}
.stage_body .bluetimelinetitle >>> .el-card__body{
    background: #DCEDF7;
}

.stage_body .yellowtimelinetitle >>> .el-timeline-item__timestamp{
    color: #EC9D5E;
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 700;
}
.stage_body .yellowtimelinetitle >>> .el-card__body h4{
    color: #EC9D5E;
}
.stage_body .yellowtimelinetitle >>> .el-card__body p{
    color: #000000;
    font-weight: 600;
}
.stage_body .yellowtimelinetitle >>> .el-card__body{
    background: #F8EADF;
}

.stage_body .redtimelinetitle >>> .el-timeline-item__timestamp{
    color: #f56c6c;
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 700;
}
.stage_body .redtimelinetitle >>> .el-card__body h4{
    color: #f56c6c;
}
.stage_body .redtimelinetitle >>> .el-card__body p{
    color: #000000;
    font-weight: 600;
}
.stage_body .redtimelinetitle >>> .el-card__body{
    background: #fef0f0;
}
.timeline_info{
    width: 100%;
    display: inline-block;
    text-align: left;
    box-sizing: border-box;
    padding: 10px;
}
.timeline_info span{
    width: 100%;
    display: inline-block;
    line-height: 30px;
    color: #F56C6C;
    font-size: 14px;
    font-weight: 700;
}
.stage_task{
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    padding: 20px;
    
}
.stage_task_left{
    float: left;
    width: 58%;
    background: #F5F7FA;
    padding: 20px;
    border-radius: 6px;
    box-sizing: border-box;
}
.stage_task_left span{
    display: inline-block;

    font-size: 14px;
    margin-left: -120px;
    color: #ADAEB0;
    border-bottom: 1px #ADAEB0 solid;
    padding-top: 8px;
    padding-bottom: 4px;
}
.stage_task_right{
    float: right;
    width: 40%;
    background: #F5F7FA;
    box-sizing: border-box;
    padding: 20px 80px;
    border-radius: 6px;
}
.stage_task_left >>> .el-card__body{
    padding: 7px 10px;
    text-align: left;
    font-size: 14px;
    font-weight: 700;
}
.stage_task_left >>> .el-row{
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 10px;
}
.stage_task_left >>> .el-icon-warning{
    margin-top: 10px;
    color: #409EFF;
}
.stage_task_right p{
    margin: 0;
}
.stage_task_right >>> .el-divider{

    margin: 20px 0;
}
.stage_task_right_value{
    float: right;
    color: #409EFF;
    font-size: 18px;
    font-weight: 700;
}
.dowork{
    cursor: pointer;
}
</style>