<template>
    <div class="read_page">
        <layoutpage>
            <div class="page-header">
                <el-page-header @back="goBack" :content="`阅读${read.grade_text}`"></el-page-header>
            </div>
            <div class="content">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span style="font-weight: 700;">【{{ read.label_text }}】{{ read.title }}</span>
                        
                        <el-button v-if="userInfo && userInfo.type == 1" @click="addWork" size="mini" type="primary">布置作业</el-button>
                        <el-button v-if="showrest()" type="primary" @click="rest" class="el-icon-refresh-right" size="small"> 重置</el-button>
                        <el-button :type="button_type" :loading="button_loading" @click="Coll" icon="el-icon-star-off" size="mini" circle></el-button>
                    </div>
                    <div class="question_box">
                        <div class="question_left">

                            <el-tabs v-model="readname">
                                <el-tab-pane label="阅读原文" name="first">
                                    <div class="declare_box">
                                        <div class="declare_table">Directions:</div>
                                        <div class="declare" v-html="read.declare"></div>
                                    </div>
                                    
                                    <div style="text-align: left;font-size: 15px;line-height: 25px;" v-html="read.body"></div>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                        <div class="question_right">
                            <el-tabs v-model="activeName1">
                                <el-tab-pane label="测试题目" name="first">
                                    <div v-if="readtest.length">
                                        <div class="first_question" v-for="(item,index) in readtest" :key="index">
                                            <span class="question">{{ index+1 }}. {{ item.question }}</span>
                                            <div style="cursor: pointer;"> 
                                                <el-col v-for="(value,k) in item.answer" :key="k" :span="22" >
                                                    <!-- 选择题 -->
                                                    <!-- <el-card v-if="read.r_type == 1" :class="value.u_submit?(value.name == value.u_submit?'right':(value.correct?'error':'')):(options[item.id] == value.name?'right':'')" shadow="hover"> -->
                                                    <el-card shadow="hover" v-if="read.r_type == 1"
                                                        :class="`${value.u_submit ?
                                                            (value.name == value.u_submit ? 'right' : 
                                                            (((!rules.scorerules || (rules.scorerules && rules.finsh)) || score >= rules.scorerules) && value.correct ? 'error' : '')
                                                            ) : 
                                                            (options[item.id] == value.name ? 'right' : '')}`">
                                                        <div @click="optionsQuestion(item.id,value)">
                                                            <span style="font-weight: 700;">{{ value.name }}</span>. {{ value.value }}
                                                        </div>
                                                    </el-card>
                                                    <!-- 判断题 -->
                                                    <!-- <el-card v-if="read.r_type == 2" :class="value.u_submit?(value.value == value.u_submit?'right':(value.correct?'error':'')):(options[item.id] == value.value?'right':'')" shadow="hover"> -->
                                                    <el-card shadow="hover" v-if="read.r_type == 2"
                                                        :class="`${value.u_submit ?
                                                            (value.value == value.u_submit ? 'right' : 
                                                            (((!rules.scorerules || (rules.scorerules && rules.finsh)) || score >= rules.scorerules) && value.correct ? 'error' : '')
                                                            ) : 
                                                            (options[item.id] == value.value ? 'right' : '')}`">
                                                        <div @click="optionsQuestion(item.id,value)">
                                                            <span style="font-weight: 700;">{{ value.name }}</span>. {{ value.value }}
                                                        </div>
                                                    </el-card>
                                                </el-col>
                                            </div>
                                            
                                            <!-- 填空题 -->
                                            <el-form :model="fill">
                                                <el-input v-if="read.r_type == 3" size="small" v-model="fill[item.id]" style="margin-top: 15px;" placeholder="请输入选项"></el-input>
                                            </el-form>
                                            
                                        </div>
                                        <div class="first_question">
                                            <div class="question writing">
                                                <div v-html="read.gogito"></div>
                                            </div>
                                            <el-col :span="22">
                                          
                                                    <el-input v-model="gogito" type="textarea" ></el-input>
                                            
                                            </el-col>
                                        </div>
                                        
                                        <el-button type="primary" @click="submit" size="small">确认提交</el-button>
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="答案解析" name="second">
                              
                                    <el-empty v-if="!isNotEmptyObject()" description="未做题或正确率未达到"></el-empty>
                                    <div v-if="isNotEmptyObject()" class="first_question">
                                        <el-col v-for="(item,index) in decipher" :key="index" :span="22">
                                            <el-card shadow="never">
                                                <span style="font-weight: 700;">{{ index+1 }}、</span>{{ item }}
                                            </el-card>
                                        </el-col>
                                    </div>
                                    
                                </el-tab-pane>
                                <el-tab-pane v-if="read.remark" label="专家点评" name="third">
                                    <div style="white-space: pre-line;text-align: left;">
                                        {{ message.m_body }}
                                    </div> 
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                    </div>
                </el-card>
            </div>
            <Readwindow v-if="task_id && worktype == 0" :plan_id="plan_id.toString()" :worktype="worktype.toString()" :task_id="task_id.toString()" :periods="periods.toString()" temptype="read" @call_back="reDetail"></Readwindow>
            
            <Arrangement :dialogVisible="dialogVisible" @success="back_success" @update:dialogVisible="updatedialogVisible" :wid="id.toString()" table="read_type"></Arrangement>
        </layoutpage>
    </div>
</template>
<script>
import Http from "../../apis/read";

import arrangement from "../../../untils/arrangement.vue";
import readwindow from "../../../untils/read/readwindow";
export default {
    components:{
        'Arrangement':arrangement,
        'Readwindow':readwindow
    },
    data(){
        return{
       
            button_loading:false,
            button_type:'info',
            fill:{},
            readname:'first',
            activeName1:'first',
            id:'0',
            read:{},
            readtest:[],
            message:{},
            decipher:[],
            options:{},
            gogito:'',
            in_time:0,
            score:0,
            task_id:0,
            plan_id:0,
            submitanswers:false,
            periods:'',
            worktype:'',    //0 周期作业 1临时作业
            row_type:0,
            userInfo:{},
            dialogVisible:false,
            //plan_identifying:'',
            type_identifying:'',
            periods:'',
            rules:{}
        }
    },
    computed: {  
        isNotEmptyObject() {  
            return function(obj) {
                if(this.rules.scorerules){
                    if(this.score >= this.rules.scorerules || this.rules.finsh){
                        return true;
                    }
                    return false;
                    
                }else{
                    if(this.rules.finsh){
                        return true;
                    }
                    return false;
                }
            };  
        },

        showrest(){
            return function(){
                if(((this.userInfo && this.userInfo.type == 0) && this.rules.flag) && this.read.r_type != 3 && !this.rules.finsh){
                    return true;
                }else{
                    return false;
                }
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        this.activeName1 = "first"
        next(); // 继续路由跳转
    },
    activated(){
        this.options = {}
        this.fill = {}
        this.readtest = []

        // 用户
        this.userInfo = JSON.parse(window.sessionStorage.getItem("userinfo"));
        this.readtest = []
        this.id = this.$route.query.id
        this.task_id = this.$route.query.task_id
        this.plan_id = this.$route.query.plan_id
        this.periods = this.$route.query.periods
        this.worktype = this.$route.query.worktype
        this.row_type = this.$route.query.type
        this.getDetail_()

        if(this.plan_identifying){
            //this.getTask()
        }
    },
    methods:{
        rest(){
            this.options = {}
            var data = this.readtest
            
            for(var index in data){
            
                for(var i in data[index].answer)
                data[index].answer[i].u_submit = ''
            }
        },
        back_success(visible){
            this.dialogVisible = visible
        },
        addWork(){
            this.dialogVisible = true
        },
        updatedialogVisible(e){
            this.dialogVisible = e
        },
        changeAnswer(value,number){
          
        },
        reDetail(item){
            this.id = item.wid
            this.task_id = item.task_id
            this.plan_id = item.plan_id
            this.row_type = item.row_type
            this.worktype = item.worktype
            this.periods = item.periods

            this.getDetail_()
        },
        getDetail_(){
            var params = {}
            params.id = this.id
            console.log(params)
            Http.getDetail(params).then((res) => {
                var fill = {}
                for(var index in res.data.readtest){
                    fill[res.data.readtest[index].id] = res.data.readtest[index].u_submit
                }
                this.fill = fill
                this.readtest = res.data.readtest
                this.button_type = res.data.collection?'primary':'info'
                this.read = res.data.read
                this.message = res.data.message
                this.decipher = res.data.decipher
                this.in_time = res.data.time
                this.submitanswers = res.data.submitanswers
                this.gogito = res.data.gogito
                this.score = res.data.score
                this.rules = res.data.rules
            });
        },
        /* 
        *   r_type 1选择题 2判断题 3填空题
        */
        optionsQuestion(id,option){
            var params = JSON.parse(JSON.stringify(this.options))
            
            if(this.read.r_type == 1){
                params[id] = option.name
            }else if(this.read.r_type == 2){
                params[id] = option.value
            }
            this.options = params
        },
        submit(){
            var params = {}
            params.w_id = this.read.id
            params.r_type = this.read.r_type
            params.body = this.read.r_type == 3?this.fill:this.options
            params.gogito = this.gogito
            params.table_type = 'read'
            params.source = 'PC'
            params.in_time = this.in_time
            params.task_id = this.task_id
            params.plan_id = this.plan_id
            params.periods = this.periods
            params.worktype = this.worktype
            params.type = this.row_type
            Http.submitAnswer(params).then((res) => {
                
                if(res.code){
                    
                    this.score = res.data.score * 100
                    this.$alert(res.msg, '提示', {
                        confirmButtonText: '确定'
                    });
                    this.getDetail_()
                }else{
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: 'error'
                    });
                }
                
            });
            
        },
        Coll(){
            if(!this.userInfo){
                this.$router.push({name:'Login',query:{redirect:encodeURIComponent(window.location.href)}});
                return false;
            }
            this.button_loading = true
            var params = {}
            params.wid = this.id
            params.tabel_type = 'read'
            params.title = this.read.title
            Http.Coll(params).then((res) => {
                this.button_loading = false
                if(this.button_type == 'primary'){
                    this.button_type = 'info'
                }else{
                    this.button_type = 'primary'
                }
    
                this.$message({
                    showClose: true,
                    message: res.msg,
                    type: res.code ? 'success' : 'error'
                });
                
            });
        },
        goBack(){
            window.history.back();
        }
    }
}
</script>
<style scoped>
.content{
    width: 1200px;
    margin: 0 auto;
}
.content >>> .el-card__header{
    display: inline-block;
    width: 100%;
}
.content .screen span{
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #FFF;
    border: 1px solid #DCDFE6;
    color: #606266;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 9px 15px;
    font-size: 12px;
    border-radius: 3px;
    margin-right:10px;
    margin-bottom: 10px;
}
.clearfix >>> .el-button{
    float: right;
    margin-left: 15px;
}
.clearfix span{
    float: left;
}
.question_box{

    width: 100%;
    height: 800px;
}
.question_left{
    width: 50%;
    float: left;
    height: 800px;
    overflow-y: auto;
    box-sizing: border-box;
}
.question_left::-webkit-scrollbar {  
  width: 10px; /* 滚动条的宽度 */  
}  
  
.question_left::-webkit-scrollbar-track {  
  background: rgba(0, 0, 0, 0); /* 滚动条轨道的背景色，设置透明度 */  
}  
  
.question_left::-webkit-scrollbar-thumb {  
  background: rgba(0, 0, 0, 0); /* 滚动条滑块的颜色，设置透明度 */  
  border-radius: 10px; /* 圆角效果 */  
}  
  
.question_left::-webkit-scrollbar-thumb:hover {  
  background: rgba(0, 0, 0, 0); /* 滚动条滑块hover时的颜色，设置透明度 */  
}
.question_left >>> .el-tabs__header{
    position: sticky;
    background: #ffffff;
    top: 0;
    z-index: 99;
}
.question_left >>> .el-tabs__content{

    padding: 0 20px;
}
.question_right{
    width: 50%;
    display: inline-block;
    height: 800px;
    overflow-y: auto;
}
.question_right >>> .el-tabs__header{
    position: sticky;
    background: #ffffff;
    top: 0;
    z-index: 99;
}
.question_right::-webkit-scrollbar {  
  width: 10px; /* 滚动条的宽度 */  
}  
  
.question_right::-webkit-scrollbar-track {  
  background: rgba(0, 0, 0, 0); /* 滚动条轨道的背景色，设置透明度 */  
}  
  
.question_right::-webkit-scrollbar-thumb {  
  background: rgba(0, 0, 0, 0); /* 滚动条滑块的颜色，设置透明度 */  
  border-radius: 10px; /* 圆角效果 */  
}  
  
.question_right::-webkit-scrollbar-thumb:hover {  
  background: rgba(0, 0, 0, 0); /* 滚动条滑块hover时的颜色，设置透明度 */  
}
.first_question {
    padding: 20px;
    width: 95%;
    display: inline-block;
    text-align: left;
    margin-bottom: 15px;
}
.first_question >>> .el-textarea__inner{
    min-height: 150px !important;
}
.first_question >>> .el-card__body{
    padding: 10px;
}
.first_question >>> .el-col{
    margin-top: 15px;
    font-size: 14px;
    line-height: 20px;
}
.first_question .question{
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    line-height: 23px;
    display: inline-block;
}
.right{
    background: #75A3E4 !important;
    color: #ffffff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    
}
.error{
    background: #FF6E6E !important;
    color: #ffffff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}
.page-header{
    width: 1200px;
    background: #ffffff;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
}
.page-header >>> .el-page-header{
    line-height: 40px;
    padding: 5px 15px;
}
.page-header >>> .el-page-header__content{
    font-size: 15px;
}
.declare_box{
    background: #F6F7FF;
    font-size: 14px;
    padding: 10px;
    border-radius: 7px;
}
.declare_table{
    text-align: left;
    font-weight: 700;
    margin-bottom: 10px;
}
.declare{
    display: inline-block;
    
    text-align: left;
    line-height: 27px;
    text-indent: 25px;
}

</style>