import Vue from 'vue';  
import Vuex from 'vuex';  
import axios from 'axios';  
  
Vue.use(Vuex);  
  
export default new Vuex.Store({  
    state: {  
        config: null, // 初始化为null，表示尚未从后端获取  
    },  
    mutations: {  
        SET_CONFIG(state, config) {  
            state.config = config;  
        },  
    },  
    actions: {  
        fetchConfig({ commit }) {  

            const config = {
                headers:{
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'token':window.sessionStorage.getItem('token')
                  }
            };

            // 添加 return 使组件可以获取 Promise 链
            return axios.get('/api/store/config',config)
                .then(response => {  
                    commit('SET_CONFIG', response.data);
                    return response.data.data; // 可选：传递数据给组件
                })  
                .catch(error => {  
                    console.error('Error fetching config:', error);  
                    return Promise.reject(error); // 关键修改：抛出错误给组件
                });  
        },  
    },  
    getters: {  
        config: state => state.config, // 创建一个getter来访问config状态  
    },  
    // 在Vue实例创建之前获取配置  
    modules: {  
        // ... 其他模块  
    },  
});