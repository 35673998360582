<template>
    <div>
        <div class="page-header">
            <el-page-header @back="goBack" :content="typetext"></el-page-header>
        </div>
        <!-- 单选 -->
        <div class="content">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span style="font-weight: 700;">{{ specialty.title }}</span>
                    
                    <el-button v-if="userInfo && userInfo.type == 1" @click="addWork" size="mini" type="primary">布置作业</el-button>
                    <el-button v-if="showrest()" type="primary" @click="rest" class="el-icon-refresh-right" size="small"> 重置</el-button>
                    <el-button :type="button_type" :loading="button_loading" @click="Coll" icon="el-icon-star-off" size="mini" circle></el-button>
                </div>
                <div class="question_box">
                    <div class="question_left" >
                        <el-tabs v-model="specialtyname">
                            <el-tab-pane label="单项选择" name="first">

                                <div v-if="specialtytest && specialtytest.length">
                                    <div class="first_question" v-for="(item,index) in specialtytest" :key="index">
                                        <span class="question">{{ index+1 }}. {{ item.question }}</span>
                                
                                        <el-col v-for="(value,k) in item.answer" :key="k" :span="22" >
                                            
                                            <!-- <el-card :class="item.u_submit?(value.name == item.u_submit?'right':(value.correct?'error':'')):(options[item.id] == value.name?'right':'')" shadow="hover"> -->
                                            <el-card shadow="hover" v-if="value.value && value.value != '/'"
                                                :class="`${item.u_submit ?
                                                    (value.name == item.u_submit ? 'right' : 
                                                    ((!rules.scorerules || (rules.scorerules && rules.finsh) || score >= rules.scorerules) && value.correct ? 'error' : '')
                                                    ) : 
                                                    (options[item.id] == value.name ? 'right' : '')}`">
                                                <div @click="optionsQuestion(item.id,value)">
                                                    <span style="font-weight: 700;">{{ value.name }}</span>. {{ value.value }}
                                                </div>
                                            </el-card>
                                        </el-col>
                                    </div>
                                </div>
  
                                <el-button type="primary" :loading="loading" @click="submit" size="small">确认提交</el-button>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                    <div class="question_right">
                        <el-tabs v-model="testname">
                            <el-tab-pane label="答案解析" name="first">
                                <el-empty v-if="!isNotEmptyObject()" description="未做题或正确率未达到"></el-empty>
                                <div v-if="isNotEmptyObject()">
                                    <div class="first_question" v-for="(item,index) in decipher" :key="index">
                                       
                                        <p class="question">
                                            <span>{{ index+1 }}. {{ item.question }}</span>
                            
                                        </p>
                                
                                        <el-col :span="22" >
                                            
                        
                                            <span style="font-weight: 700;">解析：{{ item.r_answer }}: </span>
                                            <span style="font-weight: 700;">{{ item.options }}</span>
                                        
                                    
                                        </el-col>
                                    </div>
                                </div>
                                
                            </el-tab-pane>
           
        
                        </el-tabs>
                    </div>
                </div>
            </el-card>
        </div>
        <Arrangement :dialogVisible="dialogVisible" @success="back_success" @update:dialogVisible="updatedialogVisible" :wid="specialty.id" table="specialty_type"></Arrangement>
    </div>
</template>
<script>
import Http from "../../src/apis/specialty";
import arrangement from "../../untils/arrangement.vue";
export default {
    components:{
        'Arrangement':arrangement
    },
    props: {  
        form_data: {  
            type: Object,
        },
        userInfo:{
            type: Object,
        }
    },
    data(){
        return{
            button_loading:false,
            button_type:'info',
            specialtyname:'first',
            testname:'first',
            id:'',
            specialty:{},
            specialtytest:[],
            score:0,
            submitanswers:[],
            options:{},
            task_id:0,
            plan_id:0,
            periods:'',
            in_time:'',
            score:0,
            decipher:[],
            typetext:'',
            currentTime: 0,
            duration: 0,
            progress: 0,
            loading:false,
            dialogVisible:false,
            rules:{}
        }
    },
    computed: {  
        isNotEmptyObject() {  
            return function() {
                if(this.rules.scorerules){
                    if(this.score >= this.rules.scorerules || this.rules.finsh){
                        return true;
                    }
                    return false;
                    
                }else{
                    if(this.rules.finsh){
                        return true;
                    }
                    return false;
                }
            };  
        },

        showrest(){
            return function(){
                if(((this.userInfo && this.userInfo.type == 0) && this.rules.flag) && !this.rules.finsh){
                    return true;
                }else{
                    return false;
                }
            }
        }
    },  
    mounted(){
        window.addEventListener('popstate', this.handlePopState);  
    },
    methods:{
        rest(){
            this.options = {}
            var data = this.specialtytest
            for(var index in data){
                data[index].u_submit = ''
            }
        },
        addWork(){
            this.dialogVisible = true
        },
        updatedialogVisible(e){
            this.dialogVisible = e
        },
        back_success(){
            this.dialogVisible = false
        },
        handlePopState(){
            this.specialty = {}
            this.specialtytest = []
        },
        getData(){
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.4)'
            });
            var params = {}
            params.id = this.form_data.id
            Http.Detail(params).then((res) => {
                res.data.specialty.id = res.data.specialty.id.toString()
                this.specialty = res.data.specialty
                this.specialtytest = res.data.specialty_test
                this.submitanswers = res.data.submitanswers
                this.button_type = res.data.collection?'primary':'info'
                this.in_time = res.data.time
                this.decipher = res.data.decipher
                this.score = res.data.score
                this.rules = res.data.rules
                this.typetext = res.data.typetext
                loading.close()
            });

        },
       /**
        *   选择题
        */
        optionsQuestion(id,option){
            var params = JSON.parse(JSON.stringify(this.options))
            params[id] = option.name

            this.options = params
        },
        submit(){
            this.loading = true
            var params = JSON.parse(JSON.stringify(this.form_data))
            params.w_id = params.id
            delete params.id
            params.body = this.options
            params.table_type = 'specialty'
            params.source = 'PC'
            params.in_time = this.in_time
            Http.Submit(params).then((res) => {
                this.loading = false
                if(res.code){
                    this.options = {}
                    this.score = res.data.score * 100
                    this.$alert(res.msg, '提示', {
                        confirmButtonText: '确定'
                    });
                    this.getData()
                }else{
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: 'error'
                    });
                }
            });
        },
 
        // 收藏
        Coll(){
            if(!this.userInfo){
                this.$router.push({name:'Login',query:{redirect:encodeURIComponent(window.location.href)}});
                return false;
            }
            this.button_loading = true
            var params = {}
            params.wid = this.form_data.id
            if(this.button_type == 'primary'){
                this.button_type = 'info'
            }else{
                this.button_type = 'primary'
            }
            params.title = this.specialty.title

            Http.Coll(params).then((res) => {
                this.button_loading = false
                this.button_type = 'primary'
  
                this.$message({
                    showClose: true,
                    message: res.msg,
                    type: res.code ? 'success' : 'error'
                });
                
            });
        },
        goBack(){
            window.history.back();
        }
    }
}
</script>
<style scoped>
.content{
    width: 1200px;
    margin: 0 auto;
}
.content >>> .el-card__header{
    display: inline-block;
    width: 100%;
}
.clearfix >>> .el-button{
    float: right;
    margin-left: 15px;
}
.clearfix span{
    float: left;
}
.question_box{

    width: 100%;
    height: 800px;
}
.question_left{
    width: 50%;
    float: left;
    height: 800px;
    overflow-y: auto;
    box-sizing: border-box;
}
.question_left >>> .el-card__body{
    cursor: pointer;
}

.question_left::-webkit-scrollbar {  
  width: 10px; /* 滚动条的宽度 */  
}  
  
.question_left::-webkit-scrollbar-track {  
  background: rgba(0, 0, 0, 0); /* 滚动条轨道的背景色，设置透明度 */  
}  
  
.question_left::-webkit-scrollbar-thumb {  
  background: rgba(0, 0, 0, 0); /* 滚动条滑块的颜色，设置透明度 */  
  border-radius: 10px; /* 圆角效果 */  
}  
  
.question_left::-webkit-scrollbar-thumb:hover {  
  background: rgba(0, 0, 0, 0); /* 滚动条滑块hover时的颜色，设置透明度 */  
}
.question_left >>> .el-tabs__header{
    position: sticky;
    background: #ffffff;
    top: 0;
    z-index: 99;
}
.question_left >>> .el-tabs__content{

    padding: 0 20px;
}
.question_right{
    width: 50%;
    display: inline-block;
    height: 800px;
    overflow-y: auto;
}
.question_right >>> .el-tabs__header{
    position: sticky;
    background: #ffffff;
    top: 0;
    z-index: 99;
}
.question_right::-webkit-scrollbar {  
  width: 10px; /* 滚动条的宽度 */  
}  
  
.question_right::-webkit-scrollbar-track {  
  background: rgba(0, 0, 0, 0); /* 滚动条轨道的背景色，设置透明度 */  
}  
  
.question_right::-webkit-scrollbar-thumb {  
  background: rgba(0, 0, 0, 0); /* 滚动条滑块的颜色，设置透明度 */  
  border-radius: 10px; /* 圆角效果 */  
}  
  
.question_right::-webkit-scrollbar-thumb:hover {  
  background: rgba(0, 0, 0, 0); /* 滚动条滑块hover时的颜色，设置透明度 */  
}
.first_question {
    padding: 20px;
    width: 95%;
    display: inline-block;
    text-align: left;
    margin-bottom: 15px;
}
.first_question >>> .el-textarea__inner{
    min-height: 150px !important;
}
.first_question >>> .el-card__body{
    padding: 10px;
}
.first_question >>> .el-col{
    margin-top: 15px;
    font-size: 14px;
    line-height: 20px;
}
.first_question .question{
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    line-height: 23px;
    display: inline-block;
    width: 100%;
    text-align: left;
}
.right{
    background: #75A3E4 !important;
    color: #ffffff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}
.error{
    background: #FF6E6E !important;
    color: #ffffff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}
.specialtytest{
    width: 100% !important;
}
.symbol{
    background: #ecf5ff;
    font-size: 14px;
    padding: 5px 18px;
    border-radius: 20px;
    margin-left: 10px;
}
.page-header{
    width: 1200px;
    background: #ffffff;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
}
.page-header >>> .el-page-header{
    line-height: 40px;
    padding: 5px 15px;
}
.page-header >>> .el-page-header__content{
    font-size: 15px;
}
</style>