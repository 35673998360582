<template>
    <div class="layoutspage">
        <el-container>
            <el-header style="height: 80px;">
                <div class="header_box">
                    <img src="../../static/image/logo-min.png">
                    <div class="header_menu">
                        <span @click="clickPage('Index')" :class="`${['Index'].includes(selectPage)?'check_page':''}`" >首页</span>
                        <span @click="clickPage('ReadIndex')" :class="`${['ReadIndex','ReadDetail'].includes(selectPage)?'check_page':''}`">阅读在线</span>
                        <span @click="clickPage('SpecialtyIndex')" :class="`${['SpecialtyIndex','SpecialtyDetail'].includes(selectPage)?'check_page':''}`">应试英语</span>
                        <span @click="clickPage('CultureIndex')" :class="`${['CultureIndex'].includes(selectPage)?'check_page':''}`">文化赏析</span>
                        <span @click="clickPage('DownIndex')" :class="`${['DownIndex'].includes(selectPage)?'check_page':''}`">资源下载</span>
                        <span @click="clickPage('WikiIndex')" :class="`${['WikiIndex'].includes(selectPage)?'check_page':''}`">百科讲堂</span>
                        <span @click="clickPage('SkillIndex')" :class="`${['SkillIndex'].includes(selectPage)?'check_page':''}`">技能训练</span>
                        <span @click="clickPage('CampusIndex')" :class="`${['CampusIndex'].includes(selectPage)?'check_page':''}`">校园生活</span>
                        <span @click="clickPage('MatchIndex')" :class="`${['MatchIndex','MatchDetail'].includes(selectPage)?'check_page':''}`">知识竞赛</span>
                    </div>
                    <div @click="search" style="margin-top: 15px;width: 150px;display: inline-block;padding: 8px 10px;background-color: #FFF;border-radius: 4px;border: 1px solid #DCDFE6;box-sizing: border-box;color: #606266;font-size: 14px;color: #999999;text-align: left;cursor: pointer;" class="el-icon-search">
                        搜索
                    </div>
                    <div class="signIn">
                        <div v-if="!userInfo">
                            <span @click="page('Login')">登录</span>
                            <el-divider direction="vertical"></el-divider>
                            <span @click="page('Login')" style="color: #409EFF;">注册</span>
                        </div>
                        <div v-if="userInfo">
                            <span @click="goInfo">{{ userInfo.name?userInfo.name:'匿名' }}</span>
                            <el-divider direction="vertical"></el-divider>
                            <span @click="loginOut()" style="color: #409EFF;">退出</span>
                        </div>
                    </div>
                </div>
            </el-header>
            <el-main>
                <slot></slot>
            </el-main>
            <el-footer>
                <div class="footer_box">
                    <div class="footer_box_top">
                        <div class="footer_box_left">
                            <img src="../../static/image/dibulogo.png" />
                        </div>
                        <div class="footer_box_center">
                            <div class="about_box">
                                <p>
                                    <span class="about_box_name">关于我们</span>
                                </p>
                                <p>
                                    <span @click="gopage('/aboute')" style="margin-right: 15px;">公司概述</span>
                                    <span @click="gopage('/privacy')">协议条款</span>
                                    
                                </p>
                                <p>
                                    <span style="margin-right: 15px;">习传智库</span>
                                    <!-- <span style="margin-right: 15px;">公司业绩</span> -->
                                    <span @click="gopage('/cooperation')">商务合作</span>
                                </p>
                                <p>
                                    
                                </p>
                            </div>
                            <div class="tellus_box">
                                <p>
                                    <span class="about_box_name">联系我们</span>
                                </p>
                                <p>
                                    <span>电话：18628952895</span>
                   
                                </p>
                                <p>
                                    <span>邮箱：2192887363@qq.com</span>
               
                                </p>
                                <p>
                                    <span>Q Q：2192887363</span>
                                </p>
                            </div>
                        </div>
                        <div class="footer_box_right">
                            
                            <div class="left_image">
                                <span>Android 下载</span>
                                <img src="../../static/image/code.png" />
                            </div>
                            <div class="right_image">
                                <span>IOS 下载</span>
                                <img src="../../static/image/ios.png" />
                            </div>
                        </div>
                    </div>
                    <div class="footer_box_bottom">
                        <div>
                            <span>习传英语WEB系统著作权证书No.03092410</span>
                            <el-divider direction="vertical"></el-divider>
                            <span>Copyight@2007 版权所有: 四川习传文化传播有限公司</span>
                            <el-divider direction="vertical"></el-divider>
                            <span style="cursor: pointer;" @click="gobeian">备案/许可证编号:蜀ICP备15023800号-1</span>
                        </div>
                    </div>
                </div>

            </el-footer>
        </el-container>
    </div>
</template>
<script>
import search from "../../untils/search.vue";
import User from "../apis/user"
export default {
    components:{
        'Search':search
    },
    data() {  
        return {  
            selectPage:'',
            userInfo:{},
            
        }
    },
    activated(){
        this.selectPage = this.$router.currentRoute.name
        this.userInfo = JSON.parse(window.sessionStorage.getItem("userinfo"))
    },
    mounted(){
        
      
    },

    methods: {  
        clickPage(pageName){

            var _this = this
            var name = pageName
            _this.$router.push({name:name});
        },

        page(page){
            var _this = this
            _this.$router.push({name:page});
        },
        goInfo(){
            var type = this.userInfo.type

            if(type == 0){
                this.$router.push({name:'StudentPlan'})
            }else{
                this.$router.push({name:'Plan'})
            }
        },
        search(){
            this.$router.push({name:'SearchIndex'});
        },
        loginOut(){
            User.Logout().then((res) => {
                if(res.code){
                    this.userInfo = {}
                    window.sessionStorage.clear()
                    this.$router.push({path:'/Login'});
                }
                
            });
        },

        gobeian(){
            window.open('https://beian.miit.gov.cn/', '_blank');
        },

        gopage(path){

            this.$router.push({path:path});

        },
        
    },  
}
</script>
<style scoped>
.header_box{
    width: 1200px;
    display: inline-block;
    margin: 0 auto;
    padding: 10px 0;
}
.header_box img{
    float: left;
}
.header_menu{
    float: left;
}
.header_menu span{
    width: 88px;
    display: inline-block;
    line-height: 60px;
}
.check_page{
    color: #409EFF !important;
}
.signIn{
    line-height: 60px;
    float: right;
}
.layoutspage >>> .el-main{
    min-height: calc(100vh - 60px - 220px);
    margin-bottom: 20px;
    padding: 0;
}
.layoutspage >>>.el-footer{
    height: 200px !important;
    background: #252525;
}
.footer_box{
    width: 1200px;
    height: 200px;
    margin: 0 auto;
    color: #ffffff;
}
.footer_box p{
    margin-bottom: 10px;
    margin-top: 10px;
}
.footer_box_top{
    width: 100%;
    height: 150px;
}
.footer_box_bottom{
    width: 100%;
    height: 30px;
    margin-top: 5px;
    line-height: 35px;
}
.footer_box_left{
    width: 200px;
    height: 150px;
    float: left;
    box-sizing: border-box;
    padding: 30px;
}
.footer_box_left img{
    width: 100%;
}
.footer_box_center{
    width: 600px;
    height: 150px;
    float: left;
    box-sizing: border-box;
    padding: 20px 0;
}
.footer_box_right{
    width: 400px;
    height: 150px;
    float: right;
    text-align: center;
    
}
.footer_box_right >>> .el-image{

    width: 100px;
}
.about_box{
    width: 50%;
    text-align: left;
    display: inline-block;
}
.about_box span{
    cursor: pointer;
}
.about_box .about_box_name{
    border-bottom: 2px #ffffff solid;
    padding-bottom: 5px;
    font-size: 16px;
}
.tellus_box{
    width: 50%;
    text-align: left;
    display: inline-block;
}
.tellus_box .about_box_name{
    border-bottom: 2px #ffffff solid;
    padding-bottom: 5px;
    font-size: 16px;
}
.left_image{
    width: 50%;
    display: inline-block;
}
.left_image span{
    width: 100%;
    display: inline-block;
    padding: 15px 0;
}
.left_image img{
    width: 100px;
}
.right_image{
    width: 50%;
    display: inline-block;
}
.right_image span{
    width: 100%;
    display: inline-block;
    padding: 15px 0;
}
.right_image img{
    width: 100px;
}
.header_menu span{
    cursor: pointer;
}
.signIn span{
    cursor: pointer;
}
.layoutspage >>> .el-header{
    background: #ffffff;
}

</style>